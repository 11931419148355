<template>
  <div class="exchangeCon">
    <div class="tabs">
      <span @click="$router.push('/my/exchange')">{{
        $t("vip.redeem_points")
      }}</span>
      <span @click="$router.go(-1)">
        /
        {{
          info.coupontype == 7
            ? $t("vip.credit")
            : info.coupontype == 9
            ? $t("vip.privilege_card")
            : $t("vip.free_shipping")
        }}
      </span>
      <span
        >&nbsp;/&nbsp;{{
          $i18n.locale == "en" ? info.titleen : info.title
        }}</span
      >
    </div>
    <div class="banner">
      <q-img :src="info.picture" />
    </div>
    <div class="warning">
      <h5 class="blod">{{ $t("vip.introduce") }}</h5>
      <!-- 礼券 -->
      <div class="wtxt">
        {{ $i18n.locale == "en" ? info.introduceen : info.introduce }}
      </div>
    </div>
    <!-- 兑换弹框 -->
    <div class="masktip">
      <div class="name blod">
        {{ $i18n.locale == "en" ? info.titleen : info.title }}
      </div>
      <div class="price blod" v-if="info.coupontype != 8">{{ info.Price }}</div>
      <div class="internum">
        <span class="blod">{{ formatIntegral(info.integral) }}</span>
        {{ $t("vip.points") }}
      </div>
      <div class="blackBtn">
        <q-btn
          dense
          :loading="btnLoading"
          :disable="info.isreceive == 0?true:false"
          type="submit"
          class="full-width"
          color="primary"
          :label="
            info.isreceive == 1 ? $t('vip.exchange') : $t('vip.not_available')
          "
          @click="isreceive"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getPrize } from '@/api/my'
import { formatIntegral } from '@/utils/filterData.js'

export default {
  data () {
    return {
      info: '',
      btnLoading: false
    }
  },
  methods: {
    async isreceive () {
      this.btnLoading = true
      const res = await getPrize({
        VIPdiscountguid: this.info.guid,
        action: 'user_exchange'
      })
      if (res.status === 1) {
        this.$q.notify(this.$t('vip.exchange_succeeded'))
      } else {
        this.$q.notify(this.$i18n.locale === 'en' ? res.contenten : res.content)
      }
      this.btnLoading = false
    }
  },
  mounted () {
    this.info = JSON.parse(this.$route.query.info)
    const num = parseFloat(this.info.Price)
    let n = ''
    if (num < 1) {
      n = num * 10 + this.$t('vip.discount')
    } else {
      n = '￥' + num
    }
    this.info.Price = n
  },
  setup () {
    return {
      formatIntegral
    }
  }
}
</script>

<style lang="scss" scoped>
.exchangeCon {
  position: relative;
  .tabs {
    cursor: pointer;
    text-transform: uppercase;
  }
  .masktip {
    width: 416px;
    min-height: 100px;
    background-color: #fff;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 200px;
    margin-left: -208px;
    border: 1px solid #8a8a8a;
    .name {
      font-size: 14px;
      margin: 20px auto 0 auto;
    }
    .price {
      font-size: 20px;
      margin-bottom: 15px;
    }
    .internum {
      font-size: 14px;
    }
    .blackBtn {
      width: 280px;
      margin: 15px auto;
    }
  }
}
.banner {
  height: 200px;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: 20px 0;
  margin-bottom: 150px;
}
.warning {
  h5 {
    font-size: 14px;
    margin-bottom: 0;
  }
  p {
    line-height: 20px;
    font-size: 12px;
  }
  p.marginb30 {
    margin-bottom: 30px;
  }
  .wtxt2 {
    p {
      margin-bottom: 16px;
    }
  }
}
</style>
